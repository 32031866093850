// 
// Global: breadcrumbs
// 

.page-breadcrumbs{
    background-color: $gray;
    padding: 25px 0;
}

.breadcrumbs{
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
}

.breadcrumbs li{
    color: $secoundary;
    font-weight: 600;
    font-size: 14px;
}

.breadcrumbs a{
    color: $black;
}

.breadcrumbs li:not(:last-child)::after{
    content: "-";
}


@include breakpoint(small down) {
    .page-breadcrumbs{
        display: none;
    }

    .breadcrumbs li:not(:last-child)::after{
        margin: 0 0.35rem;
    }
}