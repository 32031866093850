// 
// layout: header
// 
.header{
    position: fixed;
    left: 80px;
    right: 0;
    top: 0;
    z-index: 99;
    padding: 0px 20px;
    height: 95px;
    background-color: $primary;
    transition: all 0.3s;

    .header-row{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header-brand{
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 60px;
        img{
            height: 55px;
            width: auto;
        }
    }
    .button{
        margin: 0;
    }
}

.page-header-color{
    margin-top: 120px;
}

.page-header-transparent{
    margin-top: 0;
    .header{
        background-color: transparent;
    }
}

.page-scroll{
    .header{
        background-color: $primary;
    }
}

@include breakpoint(small down) {
    .header{
        display: none;
    }

    .page-header-color{
        margin-top: 80px;
    }
}
