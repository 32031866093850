// 
// component: card
// 

.card{
    border:0px;
    margin-bottom: 20px;
    a{
        color: $dark;
        &:hover{
            color: $secoundary;
        }
    }
}

.card-body{
    padding: 20px;
    border:1px solid transparent;
}

.card-meta, .card-title{
    font-weight: 600;
}

.card-title{
    padding-bottom: 20px;
}
.card-text{
    font-size: 18px;
    font-weight: 400;
}

.card:hover{
    .card-body{
        border-color: $gray-200;
    }
}


@include breakpoint(xlarge down) {
    .card-body{
        padding: 20px 10px;
    }
    .card-title{
        padding-bottom: 10px;
    }
}


@include breakpoint(small down) {
    .card-text{
        font-size: 16px;
    }
}