// 
// layout: offcanvas
// 
.offcanvas-container {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: $primary;
    transform: translate3d(-100%, 0%, 0);
    z-index: 99;
    width: 100%;
    transition: all .4s;
    .offcanvas-inner{
        padding-left: 80px;
    }
    ul.offcanvas-grid{
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        height: 100vh;
        > li{
            flex: 0 1 25%;
            display: flex;
            border-right: 1px solid #222229;
            border-bottom: 1px solid #222229;
            overflow: hidden;
            position: relative;
        }
        > li:hover .item-icon{
            color: $secoundary;
        }
    }

    .offcanvas-grid-item{
        position: relative;
        height: 50vh;
        width: 100%;
        width: calc(100% + 50px);
        padding: 60px 10% 20px 10%;
        padding-right: calc(10% + 50px);
        margin-right: -30px;
        transition: all 0.25s;
        overflow-y: auto;
        margin-right: -50px;
        background-color: $primary;
        
        .offcanvas-grid-item-content{
            position: relative;
            z-index: 3;
        }
        .item-icon{
            font-size: 60px;
            color: rgba($white, 0.3);
            line-height: 1;
            transition: all 0.25s;
        }
        .item-title{
            display: block;
            color: $white;
            font-size: 30px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 2px;
            padding-bottom: 20px;
            transition: all 0.25s;
            &:hover, &:focus{
                color: $secoundary;
            }
        }
        ul.item-menu {
            margin: 0;
            padding-bottom: 60px;
            transition: all 0.3s; 
            > li{
                display: block;
                margin-bottom: 6px;
            }
            > li:last-child{
                margin-bottom: 0;
            }
            > li > a{
                font-size: 16px;
                font-weight: 500;
                color: $white;
                text-transform: uppercase;
                &:hover, &:focus{
                    color: $secoundary;
                }
            }
        }
    }

    ul.offcanvas-grid > li:hover {
        .offcanvas-grid-item{
            background-color: $primary-100;
        }
    }
}

#offcanvas.active{
    transform: translate3d(0, 0, 0);
}


@include breakpoint(xlarge up) {
    .offcanvas-container{
        .offcanvas-grid-item .offcanvas-grid-item-content{
            transition: all 0.3s;
            opacity: 0;
            transform: translateY(50px);
        }
    }

    .offcanvas-container ul.offcanvas-grid > li:nth-child(1) .offcanvas-grid-item .offcanvas-grid-item-content {
        transition-delay: 0.25s;
    }
    .offcanvas-container ul.offcanvas-grid > li:nth-child(2) .offcanvas-grid-item .offcanvas-grid-item-content {
        transition-delay: 0.35s;
    }
    .offcanvas-container ul.offcanvas-grid > li:nth-child(3) .offcanvas-grid-item .offcanvas-grid-item-content {
        transition-delay: 0.45s;
    }
    .offcanvas-container ul.offcanvas-grid > li:nth-child(4) .offcanvas-grid-item .offcanvas-grid-item-content {
        transition-delay: 0.55s;
    }
    .offcanvas-container ul.offcanvas-grid > li:nth-child(5) .offcanvas-grid-item .offcanvas-grid-item-content {
        transition-delay: 0.65s;
    }
    .offcanvas-container ul.offcanvas-grid > li:nth-child(6) .offcanvas-grid-item .offcanvas-grid-item-content {
        transition-delay: 0.75s;
    }
    .offcanvas-container ul.offcanvas-grid > li:nth-child(7) .offcanvas-grid-item .offcanvas-grid-item-content {
        transition-delay: 0.85s;
    }
    .offcanvas-container ul.offcanvas-grid > li:nth-child(8) .offcanvas-grid-item .offcanvas-grid-item-content {
        transition-delay: 0.95s;
    }

    .offcanvas-container.active{
        .offcanvas-grid-item .offcanvas-grid-item-content{
            opacity: 1;
            transform: translateY(0px);
        }
    }
}


@include breakpoint(xlarge down) {
    .offcanvas-container{
        .offcanvas-grid-item{
            width: calc(100% + 40px);
            padding: 30px 10% 20px 10%;
            padding-right: calc(10% + 40px);
            margin-right: -40px;

            .item-icon{
                font-size: 44px;
            }

            .item-title{
                font-size: 22px;
                padding-bottom: 10px;
            }

            ul.item-menu{
                padding-bottom: 20px;
            }

            ul.item-menu > li{
                margin-bottom: 0;
            }
        }
    }
}


@include breakpoint(medium down) {
    .offcanvas-container{
        overflow-y: auto;
        ul.offcanvas-grid{
            display: block;
            padding: 40px 0;

            .offcanvas-grid-item{
                height: auto;
                width: 100%;
                margin: 0;
                padding: 20px 40px;
            }
        }
        
    }
}


@include breakpoint(small down) {
    .offcanvas-container{
        .offcanvas-inner{
            padding: 80px 0;
        }

        ul.offcanvas-grid{
            padding: 0 20px;
            .offcanvas-grid-item{
                padding: 20px 0;
            }
        }
    }
}




