// 
// Google map
// 
.g-map {
    position: relative;
    overflow: hidden;
    padding-bottom: 40%;
    .g-map-canvas {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $gray-100;
    }
}

.g-map-static {
    img {
        display: block;
        width: 100%;
    }
}


.gm-style .gm-style-iw-c{
    color: $white;
    background-color: #16161d;
    border-radius: 0px;
}

.gm-style .gm-style-iw-d{
    background-color: #16161d;
}

.gm-style .gm-style-iw-t::after{
    border-top: 11px solid #16161d;
}

.gm-style-iw-d{
    overflow: hidden!important;
}

.gm-style-iw button{
    top: 4px!important;
    right: -4px!important;
    img{
        display: none;
    }
    &:before{
        content: "\e90b";
        font-family: 'icomoon' !important;
        color: #fff;
        font-size: 10px;
    }
}

// info window
.info-map-location{
    display: block;
    padding: 20px 25px;
    color: $white;
    background-color: #16161d;
    .h5{
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 15px;
    }
    p{
        margin: 0;
        font-size: 16px;
        font-weight: 500;
    }
}


@include breakpoint(large down) {
    .g-map {
        padding-bottom: 62.5%;
    }
}

@include breakpoint(medium down) {
    .g-map {
        padding-bottom: 120%;
    }
}