// 
// Page: Reference
// 

.reference-list{
    margin-bottom: 0;
    padding-bottom: 40px;
}

.reference-list .cell{
    margin-bottom: 30px;
}

.reference-item{
    .reference-image{
        background-color: $gray;
        text-align: center;
        padding: 40px;
        img{
            border-radius: 10px;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
            transition: all 0.3s;
        }
    }
    .reference-title{
        padding: 20px;
        border:1px solid $gray-200;
        border-top: 0px;
        .h6{
            color: $dark;
            transition: all 0.3s;
        }
    }
}

.reference-item:hover{
    .reference-title .h6{
        color: $secoundary;
    }
    .reference-image img{
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
    }
}