// 
// component: pager
// 
.pager-flex{
    display: flex;
    align-items: center;
    .pager-center{
        padding: 4px 20px;
        a{
            color: $dark;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 14px;
            &:hover{
                color: $secoundary;
            }
        }
    }
}

.pager-white{
    a, .pager-center a{
        color: #fff;
    }
}

.pager-article{
    position: relative;
    padding: 20px 0;
    justify-content: space-between;
    
    .pager-arrow-text{
        font-size: 15px;
    }

    .pager-center{
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        text-align: center;
    }

    .pager-left, .pager-rigth{
        position: relative;
        z-index: 2;
        max-width: 280px;
        a{
            display: flex;
            align-items: center;
            color: $dark;
        }
    }

    .pager-left:hover, .pager-rigth:hover{
        .arrow, a{
            color: $secoundary;
        }
    }

    .arrow-left{
        margin-right: 12px;
    }

    .arrow-right{
        margin-left: 12px;
    }
}


@include breakpoint(medium down) {
    .pager-article .pager-arrow-text{
        display: none;
    }
}




