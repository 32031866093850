//
// component: swiper
//

.swiper-slide > .image{
    width: 100%;
}

.swiper-pagination{
    position: static;
    margin-top: 1rem;
}

.swiper-button-prev, .swiper-button-next{
    width: auto;
    height: auto;
    background-image: none;
    margin: 0;
    padding: 0;
}


.gallery{
    .swiper-button-prev, .swiper-button-next{
        transform: translateY(-50%);
        background-color: $white;
        width: 55px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9px;
        color: $dark;
        outline: none;
        &:hover{
            background-color: $secoundary;
            color: $white;
        }
    }
}
