// 
// Global: article
// 

article{
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
        color: $primary;
        margin-bottom: 40px;
    }
    ul, ol{
        margin: 40px 0;
    }
}

.article-hero-text{
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
        color: $primary;
        margin-bottom: 30px;
    }
    p{
        font-size: 18px;
        font-weight: 400;
    }
}

.article-content{
    padding: 40px 15%;
}

.article-meta{
    font-weight: 700;
    font-size: 16px;
}

.article-group{
    margin-bottom: 50px;
}

.article-gallery-bg{
    padding-top: 85px;
    margin-top: 40px;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        left: -50vw;
        right: 0;
        top: 0;
        bottom: 80px;
        background-color: $gray;
        z-index: -1;
    }
}



.grid-content{
    padding: 60px 0;
    .cell-content-text .inner{
        padding: 20px 60px;
        max-width: 620px;
        margin: 0 auto;
    }
    .cell-content-image-block{
        img{
            display: block;
            width: 100%;
        }
       
    }

    .cell-content-image-background{
        .image-holder{
            background-color: $gray;
        }
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
        margin-bottom: 30px;
    }
}

.grid-content-half-margin{
    padding: 30px 0;
}

.article-hero-text{
    padding: 60px 0;
    .h4{
        margin-bottom: 40px;
    }
    p{
        margin-bottom: 20px;
    }
}

.article-content-1{
    position: relative;
    .cell-content-left{
        padding-top: 70px;
        position: relative;
        &:before{
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            bottom: 240px;
            width: 70px;
            background-color: $primary;
            z-index: -1;
        }
        .inner{
            width: 560px;
            max-width: 100%;
            margin: 0 auto;
            padding: 60px 90px 20px 20px;
            
        }
    }

    .cell-content-right{
        padding: 40px 20px 0px 90px;
        //background-color: $primary;
        color: rgba(255, 255, 255, 0.6);
        position: relative;
        &:before{
            content: "";
            position: absolute;
            left: 0;
            right: -50vw;
            top: 0;
            bottom: 240px;
            background-color: $primary;
            z-index: -1;
        }
        .inner{
            width: 560px;
            max-width: 100%;
            
        }
        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
            color: $white;
            margin-bottom: 60px;
        }
        p{
            margin-bottom: 20px;
            color: rgba(255, 255, 255, 0.6);
        }
    }
}

.article-content-2{
    position: relative;
    &:before{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: -200px;
        bottom: 60px;
        background-color: $gray;
        z-index: -1;
    }

    .article-download{
        margin-bottom: 80px;
        .h4{
            margin-bottom: 40px;
        }
        .list-download{
            margin-left: -20px;
            margin-right: -20px;
        }
    }
}



@include breakpoint(xlarge down) {
    .article-content-1{
        .cell-content-right{
            padding: 40px 40px 0px 50px;
        }
    }
}


@include breakpoint(medium down) {
    .article-content {
        padding: 30px 0%;
    }

    .grid-content{
        margin: 0px;
        padding: 0;
        .cell-content-text{
            order: 1;
            .inner{
                padding: 20px;
                max-width: 100%;
                margin: 0 auto;
            }
        }
       
        .cell-content-image-block{
            order: 2
        }
        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
            margin-bottom: 20px;
        }
    }


    .article-content-1{
        
        .cell-content-left{
            padding: 0;
            &:before{
                content: none;
            }
            h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
                margin-bottom: 20px;
            }
            .inner{
                max-width: 100%;
                width: 100%;
                padding: 40px 20px;
            }
        }
        .cell-content-right{
            padding: 0;
            &:before{
                content: none;
            }
            h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
                margin-bottom: 20px;
            }
            .inner{
                background-color: $primary;
                max-width: 100%;
                width: 100%;
                padding: 40px 20px;
            }
        }

        .cell-content-right-image{
            display: none;
        }
    }
    .article-content-2{
        .article-download{
            margin: 40px 0;
            .h4{
                margin-bottom: 20px;
            }
        }
    }
}


@include breakpoint(small down) {
    .article-hero-text{
        padding: 40px 0;
        p{
            font-size: 16px;
        }
        .h4{
            margin-bottom: 20px;
        }
    }
}



