// 
// Page: Products
// 

.products-head{
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-200;
    .products-filter-select{
        width: 340px;
        max-width: 100%;
    }
}

.products-filter{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.products-filter-label{
    margin-right: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.products-category{
    margin: 30px 0;
    .product-category-title{
        padding-left: 25px;
        margin-bottom: 30px;
    }
}

.product-category-row{
    padding: 40px 0;
    display: flex;
    .products-category-image{
        flex: 0 0 45%;
    }
    .h4{
        margin-bottom: 40px;
    }
}

.products-sort-pagination{
    display: flex;
    justify-content: space-between;
    background-color: $gray;
    padding: 10px 0px 10px 25px;
    .h4{
        margin: 0px;
    }

    .sort-group{
        display: flex;
        align-items: center;
    }
}

.products-list{
    margin: 20px 0;
    .cell{
        margin-bottom: 20px;
    }
}

.products-list-title{
    padding-left: 20px;
    margin-bottom: 40px;
}




@include breakpoint(xlarge down) {
    .product-category-row{
        .h4{
            margin-bottom: 20px;
        }
    }
}



@include breakpoint(small down) {
    
    .products-filter{
        display: block;
    }
    .product-category-row{
        padding: 10px 0;
        display: block;
    }

    .products-category{
        .product-category-title{
            padding-left: 0;
        }
    }

    .products-sort-pagination{
        display: block;
        .h4{
            margin-bottom: 8px;
        }
    }

    .products-list-title{
        padding-left: 0px;
        margin-bottom: 20px;
    }
}