// 
// Page: compendium
// 


.filter-allert{
    padding-left: 60px;
    display: none;
    p{
        color: $secoundary;
        font-weight: 600;
    }
}

.compendium-filter{
    margin: 60px 0;
    .filter-row{
        display: flex;
        align-items: center;
    }
    .filter-letters{
        flex: 1 1 auto;
        display: flex;
    }
    .filter-label{
        label{
            font-size: 16px;
            font-weight: 600;
            color: $dark;
            padding-right: 12px;
            white-space: nowrap;
        }
    }
    .badge{
        border-radius: 0px;
        font-size: 15px;
        font-weight: 600;
        padding: 16px 20px;
        background-color: $white;
        border:1px solid $gray-200;
        color: $dark;
        cursor: pointer;
    }
    .badge-letter{
        text-transform: uppercase;
        padding: 16px 6px;
        flex: 1 1 auto;
        margin: 0 2px;
    }
    .badge.active, .badge:hover{
        color: $white;
        background-color: $secoundary;
        border-color: $secoundary;
    }
}

.compendium-accordion{
    .accordion-content{
        border:0px;
        padding: 40px;
    }
    .accordion-title{
        font-size: 30px;
        padding: 20px 40px;
        font-weight: 600;
        color: $dark;
        border:0px;
        border-bottom: 1px solid $gray-200;
    }

    .accordion-title:hover, .accordion-title:focus{
        background-color: transparent;
        color: $secoundary;
    }

    .accordion-title::before{
        right: auto;
        left: 0;
        top: 28px;
        font-weight: 500;
    }
}


@include breakpoint(large down) {
    .compendium-filter{
        .filter-row{
            display: block;
        }
        .badge{
            flex: 0 0 auto;
            font-size: 11px;
        }
        .badge-letter{
            flex: 1 1 auto;
        }
    }


    .compendium-accordion .accordion-title{
        font-size: 26px;
    }
}


@include breakpoint(medium down) {
    .compendium-filter{
        margin: 20px 0;
        .filter-letters{
            flex-wrap: wrap;
        }
        .badge{
            margin-bottom: 4px;
        }
        .badge-letter{
            flex: 0 0 auto;
        }
    }

    .compendium-accordion .accordion-title{
        font-size: 20px;
        padding: 20px;
    }
}