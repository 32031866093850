// 
// page: contact
// 
.contact-info{
    padding: rem-calc(60 0);
    border-top: 1px solid $gray-100;
}

.contact-data{
    margin-bottom: rem-calc(60);
}

.contact-data-container{
    padding: 40px 0 40px 120px;
    position: relative;
    .link-rotate{
        position: absolute;
        left: 0;
        top: 90px;
    }
    ul.contact-data-list{
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        > li{
            flex: 0 0 33.333%;
            background-color: $white;
            transition: all 0.25s;
        }
    }
}

.contact-data-item{
    position: relative;
    &:after{
        content: "";
        position: absolute;
        right: 0;
        top: 40px;
        bottom: 0;
        width: 1px;
        background-color: $gray-200;
    }
    .inner{
        padding: 40px;
    }
    p{
        margin: 0;
    }

    .paragraph-group{
        margin: 20px 0;
        p{
            margin: 0;
            font-weight: 600;
        }
    }
    .hours-label{
        margin-left: -20px;
        display: inline-block;
        background-color: $gray;
        padding: 16px 20px
    }
    
    ul.hours-list{
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 25px;
       
        > li{
            border-bottom: 1px solid $gray-200;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 0;
        }
        > li:last-child{
            border:0px;
        }
        p{
            margin: 0;
            font-size: 16px;
        }
        p:nth-child(1){
            font-weight: 600;
        }
    }
}

ul.contact-data-list > li:hover{
    background-color: $primary;
    .contact-data-item{
        &:after{
            content: none;
        }
        
        .h4, p{
            color: rgba($white, 0.7);
        }
        .paragraph-group p{
            color: $white;
        }
        .hours-label{
            background-color: #2e2e34;
            color: rgba($white, 0.7);
        }
    
        ul.hours-list > li{
            border-color: #2e2e34;
        }
    }
}


.contact-data-info{
    background-color: $gray;
    border-bottom: 1px solid $gray-200;
    padding: 20px;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    p{
        margin: 0 10px;
        font-weight: 600;
    }
}

.contact-group{
    border-top: 1px solid $gray-200;
    padding: 50px 0;
}

.contact-group:first-of-type{
    border:0px;
}

.contact-group-items-border{
    border: 0px;
}

.contact-item{
    margin-bottom: 50px;
    .item-category{
        min-height: 65px;
    }
    .item-body{
        border-right: 1px solid $gray-200;
    }
    .item-person{
        text-transform: uppercase;
        font-weight: 600;
    }
    .item-group{
        margin-bottom: 20px;
        p{
            margin: 0;
        }
    }
    .item-city{
        font-weight: 600;
    }
}
.contact-item-border{
    padding-top: 40px;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        left: 0;
        right: -30px;
        top: 0;
        height: 1px;
        background-color: $gray-200;
    }
}

.contact-item-category{
    position: relative;
    &:after{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -31px;    
        width: 1px;
        background-color: $gray-200;
    }
    .item-category{
        position: relative;
        &:after{
            content: "";
            position: absolute;
            left: -30px;
            top: -42px;
            width: 20px;
            height: 15px;
            background-color: $white;
        }
    }
}

ul > li:nth-child(4n) .contact-item .item-body{
    border:0px;
}

ul > li:nth-child(4n +1) .contact-item-category{
    &:after{
        content: none;
    }
    .item-category{
        &:after{
            content: none;
        }
    }
}


@include breakpoint(large down) {
    .contact-data-container{
        padding: 40px 0;
        .link-rotate{
            display: none;
        }
    }

    .contact-item{
        p{
            font-size: 16px;
        }
    }
}


@include breakpoint(medium down) {
    .contact-data-item{
        .inner{
            padding: 40px 20px;
        }
    }

    ul > li:nth-child(2n) .contact-item .item-body{
        border:0px;
    }
}


@include breakpoint(small down) {
    .contact-info{
        padding: rem-calc(30 0);
    }
    
    .contact-data{
        margin-bottom: rem-calc(30);
    }

    .contact-data-container ul.contact-data-list{
        display: block;
    }

    .contact-data-item{
        background-color: $primary;
        margin-bottom: 20px;
        &:after{
            content: none;
        }
        
        .h4, p{
            color: rgba($white, 0.7);
        }
        .paragraph-group p{
            color: $white;
        }
        .hours-label{
            background-color: #2e2e34;
            color: rgba($white, 0.7);
        }
    
        ul.hours-list > li{
            border-color: #2e2e34;
        }
    }

    .contact-data-item:after{
        content: none;
    }
    .contact-data-info{
        display: block;
        text-align: center;
    }

    .contact-item {
        margin-bottom: 25px;
    }

    .contact-item .item-category{
        height: auto;
        min-height: auto;
    }

    .contact-item .item-body{
        border:0px;
        border-bottom: 1px solid $gray-200!important;
    }

    .contact-group{
        border: 0px;
        padding: 20px 0;
    }

    .contact-item-border:before, .contact-item-border:after{
        content: none;
    }

    .contact-item-border{
        padding-top: 20px;
    }
}