// 
// component: page-hero
// 

.page-hero{
    position: relative;
    padding-bottom: 32%;
    overflow: hidden;
    background-color: $black;
    .page-hero-background, .page-hero-mask, .page-hero-content{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    .page-hero-background{
        background-size: cover;
        background-position: 50%;
        z-index: 1;
    }
    .page-hero-mask{
        background-color: rgba($black, 0.5);
        z-index: 2;
    }

    .page-hero-content{
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        .breadcrumbs a, .h1{
            color: $white;
        }
    }

    .grid-container{
        max-width: 100%;
        padding-left: 100px;
    }
}


@include breakpoint(large down) {
    .page-hero{
        padding-bottom: 42%;
        .grid-container{
            max-width: 100%;
            padding-left: 20px;
        }
    }
}


@include breakpoint(medium down) {
    .page-hero{
        padding-bottom: 50%;
        .page-title{
           margin: 0;
        }
    }
}

@include breakpoint(small down) {
    .page-hero{
        padding-bottom: 70%;
        .page-hero-content{
            padding-top: 40px;
        }
    }
}