
body {overflow-x: hidden;}

// grid
.grid-container{
    width: 100%;
    @include breakpoint(medium){
        max-width: 720px;
    }
    @include breakpoint(large){
        max-width: 960px;
    }
    @include breakpoint(xlarge){
        max-width: 1140px;
    }
    @include breakpoint(xxlarge){
        max-width: 1440px;
    }
}

.grid-padding-x-small, .grid-padding-x .grid-padding-x-small{
    margin-left: - 10px;
    margin-right: - 10px;
    
}

.grid-padding-x-small > .cell {
    padding-right: 10px;
    padding-left: 10px;
}

.grid-relative{
    position: relative;
    .link-rotate{
        position: absolute;
        left: 0px;
        top: 70px;
        transform: rotate(90deg) translateY(-200%);
    }
}

//tabs
.tabs, .tabs-content{
    border:0px;
}
.tabs-panel{
    padding: 0;
}


@include breakpoint(medium down) {
    .grid-relative{
        .link-rotate{
            display: none;
        }
    }
}