ul.recent-posts-list{
    margin: 0;
    padding: 0;
    list-style: none;
    > li{
        margin-bottom: 10px;
    }
}

.recent-post{
    display: flex;
    align-items: center;
    color: $dark;
    .recent-post-image{
        flex: 0 0 100px;
    }
    .recent-post-body{
        padding-left: 20px;
        p{
            margin: 0;
            font-size: 16px;
        }
        .post-meta{
            font-weight: 600;
        }
    }
}

.recent-post:hover{
    color: $secoundary;
}


@include breakpoint(large down) {
    .recent-post {
        .recent-post-image{
            flex: 0 0 80px;
        }
        .recent-post-body{
            padding-left: 10px;
            p{
                font-size: 14px;
                line-height: 1.2;
            }
        }
    }
}