// 
// Global: footer
// 

.footer {
    position: relative;
    z-index: 2;
    background-color: $white;
    ul {
        margin: 0;
        list-style: none;
    }
    p{
        margin: 0;
        font-size: 15px;
        font-weight: 400;
    }
    
    // footer top
    .footer-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .foter-copyright{
        display: flex;
        align-items: baseline;
        .copy{
            text-transform: uppercase;
            font-weight: 700;
            margin-right: 10px;
        }
        .comup{
            color: rgba($dark, 0.5);
            margin-left: 10px;
            &:hover{
                color: $secoundary;
            }
        }
    }
    .footer-top-newsletter{
        padding: 25px 0px;
        display: flex;
        align-items: center;
        label{
            margin: 0;
            font-weight: 700;
            padding-right: 15px;
            font-size: 16px;
            text-transform: uppercase;
        }
    }

    .footer-newsletter-form{
        position: relative;
        width: 410px;
        input,button{
            margin: 0;
        }
        input{
            box-shadow: none;
            height: 50px;
            padding: 8px 20px;
            border:1px solid $gray-100;
        }
        .form-control-danger{
            border:1px solid red;
        }
        button{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 110px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid $gray-100;
            color: $black;
            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
        }
    }
    
    // footer center
    .footer-center{
        background-color: $gray;
        padding: 40px 0px;
    }
    .footer-center-row{
        display: flex;
        justify-content: space-between;
    }
    .footer-center-menu{
        flex-grow: 1;
    }
    ul.footer-menu{
        display: flex;
        > li{
            margin-right: 70px;
        }
        .footer-menu-title{
            display: block;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 20px;
            color: $black;
        }
        a{
            color: $black;
            font-size: 15px;
            font-weight: 400;
        }
    }
    .footer-center-right{
        padding-left: 80px;
    }
    ul.footer-social-list{
        display: flex;
        justify-content: center;
        > li a{
            display: block;
            padding: 4px 8px;
            font-size: 16px;
            color: $black;
        }
    }

    // footer bottom
    .footer-bottom{
        background-color: $gray;
    }
    .footer-bottom-row{
        border-top: 1px solid $gray-200;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 0px;
        p{
            margin-right: 40px;
        }
    }

    .footer-bottom-col-data, .footer-bottom-col-currency{
        display: flex;
    }
}


@include breakpoint(xlarge down) {
    .footer {
        ul.footer-menu > li{
            margin-right: 40px;
        }
    }
}

@include breakpoint(medium down) {
    .footer {
        background-color: $gray;
        .footer-top, .footer-center, .footer-bottom, .footer-center-row, .footer-bottom-row, .footer-bottom-col-data{
            display: block;
        }

        .foter-copyright{
            display: block;
            .comup{
                margin-left: 0;
            }
        }

        .footer-top{
            padding-top: 25px;
        }
        .footer-top-newsletter{
            display: block;
        }

        .footer-newsletter-form{
            width: 100%;
        }

        .footer-center{
            padding: 20px 0;
        }
        .footer-center-right{
            padding: 20px 0;
            text-align: center;
        }

        ul.footer-menu{
            flex-wrap: wrap;
            .footer-menu-title{
                margin-bottom: 10px;
            }
            > li{
                flex: 0 0 50%;
                margin-right: 0px;
                margin-bottom: 25px;
            }
            > li > a{
                line-height: 1.1;
            }
        }

        

        .footer-bottom-row{
            padding: 10px 0;
            p{
                margin: 0;
            }
        }
    }
}
