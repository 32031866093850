//
// component: Image holder
//
.image-holder {
    display: block;
    position: relative;
    z-index: 10;
    overflow: hidden;
    &-bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: 50%;
    }
    &-img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-icon{
        position: absolute;
        z-index: 3;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 42px;
        opacity: 0;
        transition: all 0.3s;
    }
}

.image-holder:hover .image-holder-icon{
    opacity: 1;
}


.image-holder-bg-gray{
    background-color: $gray;
}


// Image holder Sizes
.image-holder {
    &-sm {
        padding-bottom: 60%;
    }
    &-md {
        padding-bottom: 72.5%;
    }
    &-lg {
        padding-bottom: 82.5%;
    }
    &-xl {
        padding-bottom: 115.5%;
    }
    &-xxl {
        padding-bottom: 137.5%;
    }
    &-squere {
        padding-bottom: 100%;
    }
}

//
// Overlay
//
.overlay {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: all 0.35s;
        background-color: rgba($secoundary, 0.8);
        opacity: 0;
    }
}

.overlay:hover {
    &:after {
        opacity: 1;
    }
}

.holder-zoom{
    .image-holder-bg{
        transform: scale(1);
        transition: all 0.45s;
    }
    &:hover{
        .image-holder-bg{
            transform: scale(1.1);
        }
    }
}


@supports (mix-blend-mode: lighten) {
    .overlay {
        &:after {
            mix-blend-mode: multiply;
            opacity: 0;
        }
    }
}