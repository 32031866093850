// 
// Page: Works
// 
ul.work-menu{
    margin: 40px -20px;
    margin-top: 0;
    li a{
        display: block;
        padding: 20px;
        padding-right: 70px;
        color: $dark;
        font-size: 30px;
        font-weight: 500;
        border-bottom: 1px solid $gray-200;
        position: relative;
       
        [class^="icon"]{
            position: absolute;
            right: 20px;
            top: 35px;
            font-size: 9px;
        }
    }
    li a:hover{
        color: $secoundary;
    }
}

ul.work-menu-single{
    margin: 0px -20px;
    li > a{
        border:0px;
    }
}

.work-tabs{
    border:0px;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
    padding: 15px 20px;
    margin: 10px -20px 40px -20px;
    .tabs-title > a{
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: $dark;
        border-right: 1px solid $gray-200;
        padding: 12px 30px;
        outline: none;
    }

    .tabs-title:last-child > a{
        border:0px;
    }
    .tabs-title:first-child > a{
        padding-left: 0;
    }

    .tabs-title > a:focus, .tabs-title > a[aria-selected='true']{
        background-color: transparent;
        color: $secoundary;
    }
}

.work-form{
    background-color: $gray;
    margin-top: 40px;
    padding: 40px 0px;
    position: relative;
    .h3{
        margin-bottom: 30px;
    }
    &:before{
        content: "";
        position: absolute;
        left: -20%;
        top: 0;
        bottom: 0;
        width: 100vw;
        z-index: -1;
        background-color: $gray;
    }

    textarea{
        min-height: 160px;
    }
    .checkbox label{
        line-height: 1.2;
    }
}

@include breakpoint(xlarge down) { 
    ul.work-menu{
        li a{
            font-size: 26px;
            [class^="icon"]{
                top: 30px;
            }
        }
    }
}

@include breakpoint(medium down) {
    ul.work-menu{
        margin: 20px 0;
        margin-top: 0;
    }

    .work-form:before{
        left: -20px;
        right: -20px;
        width: 120vw;
    }
}

@include breakpoint(small down) { 
    ul.work-menu{
        li a{
            padding-left: 0;
            font-size: 20px;
            [class^="icon"]{
                top: 30px;
            }
        }
    }

    .work-tabs{
        border: 0px;
        padding-top: 0;
        padding-bottom: 0;
        .tabs-title{
            float: none;
        }
        .tabs-title > a{
            padding-left: 0;
            border-right: 0px;
            border-bottom: 1px solid $gray-200;
        }
    }
}