
.reveal.large{
    width: 640px;
    max-width: 100%;
    .close-button, .close-button.medium{
        font-size: 14px;
        top: 15px;
        right: 15px;
    }

    .modal-footer{
        padding-right: 60px;
    }

    .list-agreement p{
        font-size: 14px;
    }
}

.reveal.banner {
    max-width: 90%;
    width: 940px;
    padding: 0;
    border:0px;
    background-color: transparent;
    text-align: center;

    .close-button {
        font-size: 14px;
        top: 15px;
        right: 15px;
        color: $secoundary;
    }
}