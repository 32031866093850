// 
// component: search form
// 

.seach-form{
    position: relative;
    margin-bottom: 16px;
    input {
        display: block;
        width: 100%;
        padding: rem-calc(10 25 10 130);
        
    }
    label{
        position: absolute;
        left: 1px;
        top: 1px;
        bottom: 1px;
        font-family: 'Rajdhani', sans-serif;
        background-color: $gray;
        display: flex;
        align-items: center;
        padding: rem-calc(5 16);
        justify-content: center;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 18px;
    }
    button {
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        background-color: transparent;
        border: 0px;
        outline: 0px;
        font-size: 16px;
        cursor: pointer;
        transition: color 0.4s;
        &:hover{
            color: $secoundary;
        }
    }
}

@include breakpoint(medium down) {
    .seach-form{
        input {
            padding: rem-calc(10 25 10 25);
        }
        label{
            display: none;
        }
    }
}