// 
// layout: page
// 

body{
    padding-left: 80px;
}
// page
.page{
    overflow: hidden;
}
.page-title-flex{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .pager-flex{
        flex: 0 0 auto;
        padding: 25px 0px 0px 60px;
    }
}

.page-content-container{
    width: 1920px;
    max-width: 100%;
    margin: 0 auto;
}

@include breakpoint(xlarge up) {
    .page-header-transparent{
        padding-top: 32%;
        .page-hero{
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            z-index: 1;
        }
    
        .page-content{
            position: relative;
            z-index: 2;
            background-color: $white;
        }
    }
    
    .page-home{
        padding-top: 100vh;
        .home-swiper{
            position: fixed;
            z-index: 1;
            left: 0;
            right: 0;
            top: 0;
        }
    }
}

@include breakpoint(large down) {
   
}


@include breakpoint(medium down) {
    .page-title-flex{
        display: block;
        .pager-flex{
            padding-left: 0;
        }
    }
}

@include breakpoint(small down) {
    body{
        padding-left: 0;
    }
}

