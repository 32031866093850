// 
// Page: Producents
// 

.producents-container-1{
    padding: 80px 0 60px 0px;
    .h4{
        margin-bottom: 30px;
    }
}

.producents-container-2{
    padding: 60px  0px;
    .h4{
        margin-bottom: 30px;
    }
}

.producents-bg{
    background-color: $gray;
    padding: 60px 0px 50px 0px;
}

.producents-list-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    .h1{
        margin: 0;
        text-transform: uppercase;
    }
}

.producent-product-list{
    .cell{
        margin-bottom: 20px;
    }
    .product{
        background-color: $white;
    }
}


.image-holder-producent{
    img{
        max-width: 308px;
    }
}


@include breakpoint(medium down) {
    .producents-container-1{
        padding: 40px 0;
    }
    .producents-list-title{
        margin-bottom: 8px;
        display: block;
        .h1{
            margin-bottom: 8px;
        }
    }
}

@include breakpoint(small down) {
    .image-holder-producent{
        img{
            width: 180px;
            max-width: 100%;
        }
    }
}


