// 
// list grid
// 
ul.list-grid{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        top: -125px;
        bottom: -25px;
        right: 0;
        width: 66.666%;
        background-color: #dd232e;
        z-index: -1;
    }
    > li{
        flex: 0 1 33.333%;
    }
}

ul.list-grid > li:hover{
    .overlay:after{
        opacity: 0;
    }
    .content-inner{
        transform: translateY(0px)!important;
    }
}

// 
// Grid item
// 
.grid-item{
    overflow: hidden;
    position: relative;
    display: block;
    .grid-item-image{
        position: relative;
        z-index: 1;
    }
    .grid-item-content{
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .content-inner{
        background-color: rgba(#16161d, 0.9);
        transition: all 0.3s;
        padding: 40px;
        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, a{
            color: $white;
        }
    }
    .content-title{
        padding-bottom: 30px;
        p{
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 600;
            margin: 0;
        }
        .h4{
            color: $white;
            font-weight: 500;
            text-transform: uppercase;
            margin: 0;
        }
    }
    .content-body{
        p{
            font-size: 18px;
            font-weight: 400;
        }
    }

    .grid-item-text{
        width: 50%;
        margin: 0 auto;
        h2, h4{
            text-transform: uppercase;
        }
        h2{
            font-weight: 400;
        }
        h4{
            font-weight: 500;
        }
    }
}


.grid-item:hover .content-title p{
    color: #dd232e;
}

@include breakpoint(xlarge up) { 
    .grid-item{
        .content-inner{
            height: auto!important;
        }
    }
}

@include breakpoint(xlarge down) { 
    .grid-item{
        .content-inner{
            padding: 20px;
        }
        .content-title{
            padding-bottom: 15px;
        }
        .content-body p{
            font-size: 16px;
        }
        .grid-item-text{
            width: 90%;
            h2{
                font-size: 54px;
            }
            p{
                font-size: 16px;
            }
        }
    }
}

@include breakpoint(large down) { 
    .grid-item{
        .grid-item-content{
            position: static;
        }
        .content-inner{
            background-color: $primary;
            transform: translateY(0px)!important;
        }
    }
}
@include breakpoint(medium down) { 
    ul.list-grid{
        > li{
            flex: 0 1 50%;
            order: 1;
        }
        > li:nth-child(n+3){
            display: none;
        }
    }

    .grid-item{
        .grid-item-text{
            width: 100%;
            padding: 20px;
            background-color: $secoundary;
            h2, h4, p, a{
                color: $white;
            }
            h2{
                font-size: 40px;
            }
            a:hover{
                color: $yellow;
            }
        }
    }
}

@include breakpoint(small down) { 
    ul.list-grid{
        margin-left: 0;
        margin-right: 0;
        > li{
            flex: 0 1 100%;
            padding: 7.5px 15px;
        }
    }

    .grid-item{
        .grid-item-text{
            h2{
                font-size: 24px;
            }
            h4{
                font-size: 20px;
            }
        }
    }
}