// main swiper
.home-swiper{
    position: relative;
    background-color: $primary;
    .swiper-slide{
        position: relative;
        height: 100vh;
    }
    .swiper-slide-bg, .swiper-slide-content{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    .swiper-slide-bg{
        background-size: cover;
        background-position: top center;
        z-index: 10;
        /*left: -10%!important;
        right: -10%!important;
        bottom: -10%!important;
        top: -10%!important;
        position: absolute!important;*/
        &:before{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba($black, 0.4);
        }
    }
    .swiper-slide-content{
        z-index: 20;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 10%;
        flex-direction: column;
        opacity: 0;
        transform: translateY(20px);
        transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.45s;
        padding-left: 80px;
        
    }
    .h1, .h2, .h3, .h4, .h5, .h6, p{
        color: $white;
    }

    .h1{
        text-transform: uppercase;
        font-size: 80px;
    }
    .h2{
        font-weight: 700;
        font-size: 80px;
        color: $secoundary;
        text-transform: uppercase;
    }
    p{
        font-size: 18px;
        font-weight: 500;
    }
    .home-swiper-scroll{
        position: absolute;
        bottom: 160px;
        left: 10px;
        right: 0;
        z-index: 30;
        .link-rotate{
            color: $white;
            &:hover{
                color: $secoundary;
            }
        }
    }

    .home-swiper-nav{
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 44;

        .arrow-left, .arrow-right {
            border:0px;
        }

    }

    .swiper-pagination{
        margin: 0;
    }

    .swiper-pagination-bullet{
        width: auto;
        height: auto;
        opacity: 1;
        background-color: transparent;
        color: $white;
        font-size: 16px;
        font-weight: 500;
        margin: 0 4px;
        outline: none;
    }

    .swiper-pagination-bullet-active{
        color: $secoundary;
    }
}

.home-swiper .swiper-slide-active {
    .swiper-slide-content {
        opacity: 1;
        transform: translateY(0px);
    }
}


@include breakpoint(xlarge down) { 
    .home-swiper{
        .h1, .h2{
            font-size: 52px;
        }
        .home-swiper-scroll{
            bottom: 150px;
        }
    }
}


@include breakpoint(large down) { 
    .home-swiper{
        .home-swiper-scroll{
            display: none;
        }
        .swiper-slide{
            //padding-bottom: 62.5%;
        }

        .swiper-slide-content{
            padding-left: 0;
            justify-content: center;
            padding-bottom: 0;
        }
    }
}


@include breakpoint(medium down) { 
    .home-swiper{
        .h1, .h2{
            font-size: 36px;
        }
        .swiper-slide{
            //padding-bottom: 92.5%;
            .h1, .h2, p{
                padding-right: 40px;
            }
        }
    }
}

@include breakpoint(small down) { 
    .home-swiper{
        p{
            font-size: 16px;
        }
        .h1, .h2{
            font-size: 26px;
        }

        .swiper-slide{
            padding-bottom: 120%;
            height: auto;
        }
        .swiper-slide-content{
            right: 10%;
        }
    }
}