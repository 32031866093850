// 
// Global: aside
//
.aside{
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    bottom: 0;
    width: 80px;
    text-align: center;
    background-color: $white;
    box-shadow: 2px 0 5px -2px rgba($black, 0.1);
    &:after{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #d90b17;
        z-index: -1;
        transition: all 0.25s;
        transform: translateX(-100%);
    }
    &-brand{
        display: none;
    }
    &-button{
        border-bottom: 1px solid #ccc;
        padding: 40px 0px 25px 0px;
        transition: all 0.25s;
        .navbar-toggler{
            outline: none;
        }
    }
    &-icon{
        font-size: 24px;
        padding-top: 20px;
        transition: all 0.25s;
        opacity: 1;
    }
    &-text{
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;
        text-align: center;
        color: $dark;
        span{
            display: block;
            width: 300px;
            height: 80px;
            position: absolute;
            left: -80px;
            top: 280px;
            white-space: nowrap;
            display: block;
            transform: rotate(-90deg);
            text-align: right;
        }

        .text-default, .text-active{
            transition: all 0.25s;
        }
        .text-default{
            opacity: 1;
            transform: rotate(-90deg) translateX(0px);
        }
        .text-active{
            opacity: 0;
            transform: rotate(-90deg) translateX(-50px);
        }
    }

    &-socials{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 40px;
    }

    ul.aside-social-list{
        margin: 0;
        padding: 0;
        list-style: none;
        display: block;
        text-align: center;
        
        > li a{
            display: block;
            padding: 4px 8px;
            font-size: 16px;
            color: $black;
        }

        > li a:hover{
            color: $secoundary;
        }
    }
}

body.menu-is-active{
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after{
        background-color: $white;
    }
    .aside{
        &:after{
            transform: translateX(0%);        
        }
        &-button{
            border-color: $black;
        }
        &-icon{
            opacity: 0;
        }
        &-text{
            color: $white;
            .text-default{
                opacity: 0;
                transform: rotate(-90deg) translateX(-50px);
            }
            .text-active{
                opacity: 1;
                transform: rotate(-90deg) translateX(40px);
            }
        }

        ul.aside-social-list{
            > li a{
                color: $white;
            }
            > li a:hover{
                color: $primary;
            }
        }
    }
}


@include breakpoint(small down) {
    .aside{
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        bottom: auto;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $primary;
        &-socials{
            display: none;
        }
    }

    .aside-brand{
        display: block;
    }
    .aside-icon, .aside-text{
        display: none;
    }

    .aside-brand{
        width: 70px;
    }
    .aside-button{
        padding: 0;
        padding-right: 10px;
        border:0px;
    }

    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after{
        background-color: $white;
    }
}

