// 
// Page: Service form
// 

.service-tabs-container{
    background-color: $gray;
    padding: 20px 0;
}

.service-tabs{
    background-color: transparent;
    .tabs-title > a{
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 600;
        color: $dark;
        border-right: 1px solid #c5c3c3;
        padding: 8px 18px;
    }
    .tabs-title:first-child > a{
        padding-left: 0;
    }
    .tabs-title:last-child > a{
        border:0px;
    }
    .tabs-title > a:hover{
        background-color: transparent;
        color: $secoundary;
    }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true']{
        color: $secoundary;
        background-color: transparent;
    }
}

.service-form{
    .form-section{
        padding: rem-calc(40 0);
        position: relative;
        &:after{
            content: "";
            position: absolute;
            left: -20px;
            right: -20px;
            bottom: 0;
            height: 1px;
            background-color: $gray-300;
        }
    }
    .form-section-title{
        margin-bottom: 40px;
        .h3, p{
            margin: 0;
        }
    }
    
    .radio-group-flex{
        padding: rem-calc(20 0);
        display: flex;
    
        .radio + .radio{
            margin-left: rem-calc(20);
        }
    }
    
    .radio-group{
        padding-top: 20px;
        display: block;
        .radio{
            margin-bottom: 20px;
        }
        .radio:last-of-type{
            margin-bottom: 0;
        }
    }
    
    .checkbox-group{
        padding: rem-calc(50 0 20 0);
        display: flex;
        flex-wrap: wrap;
        .checkbox{
            flex: 0 0 45%;
            margin-bottom: 20px;
            padding-right: 20px;
        }
        .checkbox label{
            font-size: 16px;
        }
    }
    
    .service-price{
        padding-left: 40px;
        .service-price-title{
            margin-bottom: 20px;
            .h6, p{
                margin: 0;
            }
            p{
                font-size: 14px;
            }
        }
        
        .service-price-body{
            p{
                margin: 0;
            }
        }
    }
}

@include breakpoint(small down) { 
    .service-tabs{
        .tabs-title{
            float: none;
        }
        .tabs-title > a{
            padding-left: 0;
            border-right: 0px;
            border-bottom: 1px solid $gray-200;
        }
    }
    .service-form{
        .h3{
            font-size: 22px;
        }

        .form-section-title{
            margin-bottom: 20px;
        }

        .service-price{
            padding-left: 0;
        }
    }
}


