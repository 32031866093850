body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 400;
}


h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: 'Rajdhani', sans-serif;
    font-weight: 500;
    line-height: 1.2;
}

h1, .h1 {
    font-size: 72px;
    line-height: 1;
}

h2, .h2 {
    font-size: 40px;
}

h3, .h3 {
    font-size: 36px;
    font-weight: 500;
}

h4, .h4 {
    font-size: 30px;
}

h5, .h5 {
    font-size: 24px;
}

h6, .h6 {
    font-size: 18px;
}

p, ul, ol{
    font-size: 18px;
    font-weight: 400;
}


.paragraph-group{
    p{
        margin: 0;
    }
}



ul.list-bullet {
    padding: 40px 40px;
    margin: 40px -40px;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    font-size: 14px;
    li {
        position: relative;
        padding-left: 40px;
        margin-bottom: 20px;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 7px;
            width: 15px;
            height: 15px;
            background-color: $white;
            border:4px solid $gray;
            border-radius: 50%;
        }
    }
    li:last-child{
        margin-bottom: 0;
    }
}


// Lists decimal
ul.list-decimal{
    counter-reset: counter-decimal;
    list-style: none;
}
ul.list-decimal > li {
    position: relative;
    padding-left: 35px;
    margin-bottom: 10px;
}
ul.list-decimal > li:before {
    position: absolute;
    left: 0px;
    top: 0px;
    font-size: 16px;
    content: counter(counter-decimal, decimal)  ".";
    counter-increment: counter-decimal;
    font-weight: 500;
    color: $dark;
}

ul.list-decimal > li > ul{
    list-style: none;
}

ul.list-decimal > li > ul > li{
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
}
ul.list-decimal > li > ul > li:before{
    position: absolute;
    left: 0;
    top: 0;
    content: "-";
}
.page-title{
    margin-bottom: 40px;
}
.page-title .h1{
    color: $primary;
    text-transform: uppercase;
    margin: 0;
}

@include breakpoint(xlarge down) { 
    h1, .h1 {
        font-size: 56px;
    }

    h2, .h2 {
        font-size: 36px;
    }
    
    h3, .h3 {
        font-size: 30px;
    }

    h4, .h4{
        font-size: 26px;
    }

    h5, .h5{
        font-size: 20px;
    }
}

@include breakpoint(medium down) { }

@include breakpoint(small down) {
    p{
        font-size: 16px;
    }
    h1, .h1 {
        font-size: 32px;
    }
}
