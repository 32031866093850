// 
// component: product
// 

.product{
    position: relative;
    border:1px solid $gray-200;
    .product-image{
        position: relative;
        z-index: 2;
        overflow: hidden;
        padding: rem-calc(40);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.25s;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            max-height: 220px;
        }
    }

    
    .product-description{
        padding: 20px;
        position: relative;
        z-index: 2;
        p, .h6{
            margin: 0;
        }
    }
    
    .product-hover{
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($white, 0.8);
        opacity: 0;
        transition: all 0.25s;
        .button{
            margin: 3px 1px;
        }
    }
}

.product:hover{
    .product-hover{
        z-index: 3;
        opacity: 1;
    }
}

.product-fixed{
    width: 320px;
    max-width: 100%;
    display: inline-block;
    margin: 10px 0;
}

.product-promo-label{
    padding: 5px 12px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    background-color: $secoundary;
    color: $white;
    display: inline-block;
}

.product-promo{
    border-color: $secoundary;
    .product-promo-label{
        position: absolute;
        right: 0;
        top: 0;
    }
}




@include breakpoint(large down) {
    .product{
        .product-hover{
            display: block;
            padding: 10px;
            opacity: 1;
            position: static;
            .button{
                width: 100%;
            }
        }
    }
}

@include breakpoint(medium down) {
    .product-fixed{
        width: 100%;
    }
}
