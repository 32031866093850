// 
// Global: forms
// 

// form control
.form-control{
    border: 1px solid $gray-300;
    box-shadow: none;
    outline: none;
    background-color: $white;
    padding: rem-calc(12 20);
    height: rem-calc(52);
    font-size: rem-calc(16);
    font-weight: 500;
}

.form-control:focus{
    background-color: $white;
    border-color: $primary;
    box-shadow: none;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #f7f4f4;
}

.form-control:disabled:focus, .form-control[readonly]:focus{
    background-color: #f7f4f4;
    border-color: $gray-300;
}



//placeholder
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $dark;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $dark;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $dark;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $dark;
}

.form-group{
    margin-bottom: rem-calc(25);
    label{
        font-size: rem-calc(16);
        font-weight: 600;
        margin-bottom: rem-calc(4);
    }
}



// custom checkbox
.checkbox {
    position: relative;
    padding-left: rem-calc(45);
    input[type=checkbox] {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    label {
        padding: 0!important;
        margin: 0;
        position: relative;
        font-size: 14px;
        font-weight: 400;

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: - rem-calc(45);
            top: 0;
            width: rem-calc(25);
            height: rem-calc(25);
        }
        &:before {
            border: 1px solid #dadada;
        }
        &:after {
            background: no-repeat 50%/50% 50%;
        }
    }
    input:checked~label::before {
        background-color: #000;
    }
    input:checked~label::after {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
    }
}

// custom radio
.radio {
    position: relative;
    padding-left: rem-calc(30);
    input[type=radio] {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    label {
        padding: 0!important;
        margin: 0;
        position: relative;
        line-height: 1.2;
        font-size: 16px;
        font-weight: 400;
        &:before,
        &:after {
            content: "";
            position: absolute;
            left: - rem-calc(30);
            top: 0;
            width: rem-calc(20);
            height: rem-calc(20);
            border-radius: 50%;
        }
        &:before {
            border: 3px solid $gray-200;
        }
        &:after {
            background-position: 50%;
            background-size: 8px 8px;
            background-repeat: no-repeat;
        }
    }
    input:checked~label::before {
        border-color: $secoundary;
    }
    input:checked~label::after {
        background-image: url("../img/svg/radio.svg");
    }
}

//slect2
.select2-container .select2-selection--single{
    border: 1px solid $gray-100;
    box-shadow: none;
    outline: none;
    background-color: $white;
    padding: rem-calc(12 20);
    height: rem-calc(52);
    font-size: rem-calc(16);
    font-weight: 500;
    border-radius: 0px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow{
    height: auto;
    width: rem-calc(32);
    background-color: $white;
    top: 1px;
    bottom: 1px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b{
    border:0px;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 50%;
    font-size: auto;
    width: auto;
    height: auto;
    &:after{
        content: "\e90d";
        font-family: 'icomoon' !important;
        font-size: 6px;
    }
}

.select2-dropdown{
    border-color: $gray-100;
}
.select2-container--open .select2-dropdown--below{
    border-radius: 0px;
}

.select2-container--default .select2-results__option[aria-selected=true]{
    background-color: $gray;
}

.select2-container--default .select2-results__option--highlighted[aria-selected]{
    background-color: $primary;
}

//quantity buttons
.quantity {
    position: relative;
    text-align: left;
    width: rem-calc(80);
    height: rem-calc(50);
    .quantity--input {
        width: rem-calc(50);
        height: rem-calc(50);
        border: 1px solid $gray-200;
        text-align: center;
        background-color: $white;
        box-shadow: none;
        cursor: auto;
    }
    .quantity-btn {
        position: absolute;
        right: 0;
        width: rem-calc(25);
        height: rem-calc(23);
        background-color: $white;
        border: 1px solid $gray-200;
        color: $dark;
        font-size: rem-calc(14);
        font-weight: 500;
        outline: none;
        line-height: 1;
        font-weight: 300;
        transition: all 0.3s;
        cursor: pointer;
    }
    .quantity--plus {
        top: 0;
    }
    .quantity--plus:hover {
        background-color: $primary;
        color: $white;
    }
    .quantity--minus {
        bottom: 0;
    }
    .quantity--minus:hover {
        background-color: $secoundary;
        color: $white;
    }
}

// file upload single
.custom-file-single{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .custom-file-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        height: 0;
    }

    .custom-file-single-control{
        background-color: $white;
        border:1px solid $gray-200;
        padding: rem-calc(12 20);
        height: rem-calc(52);
        font-size: rem-calc(14);
        font-weight: 300;
        flex: 1 1 auto;
    }
    .custom-file-single-button{
        padding-left: 25px;
    }

    .input-file-btn{
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        color: $dark;
        cursor: pointer;
        [class^="icon"]{
            margin-left: 12px;
            font-size: 16px;
        }
    }
}
.custom-file.form-control-danger .custom-file-single-control{
    border-color: red;
}

// file upload multiple
.custom-file-multiple{
    height: 215px;
    background-color: $gray;
    background-image: repeating-linear-gradient(to right, #d4d4d4 0%, #d4d4d4 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to right, #d4d4d4 0%, #d4d4d4 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, #d4d4d4 0%, #d4d4d4 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, #d4d4d4 0%, #d4d4d4 50%, transparent 50%, transparent 100%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 10px 1px, 10px 1px, 1px 10px, 1px 10px;
    border:0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .custom-file-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        height: 0;
    }

    .input-file-btn{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    .input-file-btn-text{
        text-transform: none;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Rajdhani', sans-serif;
        margin-bottom: 4px;
    }

    .input-file-label{
        p{
            font-size: 14px;
            margin: 0;
        }
    }
}


// VALIDATION
.parslay-danger {
    display: none;
}

.form-control.form-control-danger {
    border-color: $danger;
}

.form-group.has-danger {
    .radio, .checkbox{
        label{
            color: $danger
        }
        label:before{
            border-color: $danger;
        }
    }
    .select2-container .select2-selection--single{
        border-color: $danger;
    }
}

.bootstrap-select > .dropdown-toggle.form-control-danger{
    border-color: $danger;
}

