// 
// component: sidebar
// 

.sidebar-trigger-btn{
    display: none;
}

.sidebar{
    position: relative;
    .sidebar-close{
        position: absolute;
        right: 10px;
        top: 10px;
        color: $dark;
        display: none;
    }

    .widget-title{
        text-transform: uppercase;
        font-weight: 600;
    }
    .widget{
        margin-bottom: 40px;
    }
    .widget-archive, .widget-recent-post{
        padding-left: 20px;
    }
    .h6{
        text-transform: uppercase;
        padding: rem-calc(10 16);
        margin: 0;
    }

    .accordion-menu .nested.is-accordion-submenu{
        margin-left: 0;
    }
    
    .accordion-menu > li >a{
        background-color: $white;
        color: $dark;
        padding: 0px 0px 0px 15px;
        font-size: 16px;
        font-weight: 500;
        span{
            display: block;
            padding: 16px 10px 16px 0px;
            border-bottom: 1px solid $gray-200;
        }
        &.active {
            background-color: $secoundary;
            color: $white;
        }
    }

    .accordion-menu .nested.submenu{
        padding: 10px 0px 10px 10px;
        border-bottom: 1px solid $gray-200;
        a{
            color: $dark;
            font-size: 15px;
            font-weight: 500;
            &:hover{
                color: $secoundary;
            }
            &.active {
                background-color: $secoundary;
                color: $white;
            }
        }
    }

    .accordion-menu .is-accordion-submenu-parent[aria-expanded='true']{
        > a{
            background-color: $secoundary;
            color: $white;
        }
        > a:hover{
            color: $white;
        }
        span{
            border-bottom-color: transparent;
        }
    }

    .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after{
        right: 15px;
        top: 30%;
        content: "+";
        border:0px;
        width: auto;
        height: auto;
        font-size: 18px;
        font-weight: 400;
        transform: none;
        margin: 0;
    }
    .accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after{
        transform: none;
        content: "-";
    }
}


@include breakpoint(medium down) {
    .sidebar-trigger-btn{
        display: block;
        .button{
            display: block;
            width: 100%;
        }
    }
    .sidebar{
        position: fixed;
        z-index: 999;
        left: 0;
        top: 0;
        bottom: 0;
        width: rem-calc(240);
        padding-right: 20px;
        max-width: 90%;
        background-color: $white;
        transform: translate3d(-100%, 0, 0);
        transition: all 0.4s;
        overflow-y: auto;
        .sidebar-close{
            display: block;
        }
        .banner{
            height: rem-calc(240);
        }
    }
    .sidebar.open{
        transform: translate3d(0%, 0%, 0);
    }
}

