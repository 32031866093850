// 
// component: List check
// 

ul.list-check{
    list-style: none;
    padding: 0;
    margin: 0;

    > li{
        margin-bottom: 20px;
        padding-left: 30px;
        position: relative;
        &:before{
            content: "\e923";
            font-family: 'icomoon' !important;
            color: #dd232e;
            position: absolute;
            left: 0;
            top: 5px;
            font-size: 11px;
        }
    }
}