// 
// component: pagination
// 

.pagination-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0;
    border-top: 1px solid $gray-200;
    padding: 14px 0;
}

.pagination{
    margin: 0 20px;
}

.pagination-title{
    margin: 0;
    font-weight: 400;
}

.pagination a, .pagination button{
    font-size: 14px;
    color: $dark;
    background-color: transparent;
    font-weight: 500;
    padding: 0 6px;
}

.pagination a:hover, .pagination button:hover{
    background-color: transparent;
    color: $secoundary;
}

.pagination .current{
    background-color: transparent;
    color: $secoundary;
    font-size: 14px;
    font-weight: 700;
}
.paginnation-buttons{
   display: flex;
    .button-arrow + .button-arrow{
        margin-left: 2px;
    }
}