// 
// Page: Product single
// 

.product-single-image{
    border:1px solid $gray-200;
    text-align: center;
    padding: 40px 0;
}
.product-single{
    .product-promo-label{
        font-weight: 700;
        margin-bottom: 10px;
    }
    .product-title{
        padding: 0 20px;
        margin-bottom: 30px;
        .h4, p{
            margin: 0;
        }
    }
    .product-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 20px;
        border-top: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
        margin-bottom: 40px;
        .button, p{
            margin: 0;
        }
    }
    .product-description, .product-download, .product-info, .product-form{
        padding: 0 20px;
    }

    .product-download{
        margin: 40px 0;
        ul.list-download{
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    .product-info{
        margin: 40px 0;
        table{
            margin-top: 20px;
            margin-left: -20px;
            margin-right: -20px;
            thead, tbody, tfoot{
                border:0px;
            }
    
            tbody tr, tbody tr:nth-child(even){
                background-color: $white;
            }
            tbody tr{
                border-bottom: 1px solid $gray-200;
            }
            tbody td{
                padding: 8px 20px;
            }
            tr > td:first-child{
                font-weight: 600;
            }
            tr > td:last-child{
                font-weight: 400;
            }
        }
        
    }

    .product-form{
        form{
            margin-left: -20px;
            margin-right: -20px;
        }
        .form-group{
            margin-bottom: 8px;
            .form-control{
                margin-bottom: 0;
            }
        }
    }
}



@include breakpoint(medium down) {
    .product-single{
        .product-title{
            margin: 20px 0px;
        }
    }
}

@include breakpoint(small down) {
    .product-single{
        .product-head{
            display: block;
            padding: 8px 20px;
            .product-ean{
                margin-bottom: 8px;
            }
        }
    }
}