// 
// Page: Home
// 

.section-title{
    text-transform: uppercase;
}
.link-rotate{
    display: inline-flex;
    transform: rotate(90deg);
    transform-origin: left top;
    align-items: center;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
    transition: all 0.3s;
    color: $dark;
    [class^="icon"]{
        animation: anim-bounce-sm 0.85s ease-in-out infinite alternate;
        font-size: 9px;
        margin-left: 6px;
        position: relative;
        left: 0;
    }
    &:hover{
        color: $secoundary;
    }
}

@keyframes anim-bounce-sm {
    from {
        left: 0;
    }
    to {
        left: 10px;
    }
}

//news
.section-news{
    .news-title{
        background-color: $primary;
        padding: 60px 0;
        position: relative;
        .h1{
            font-size: 160px;
            text-transform: uppercase;
            font-weight: 300;
            color: rgba(#fff, 0.03);
        }
        .h2{
            text-transform: uppercase;
            font-size: 80px;
            color: $white;
        }
        &:before{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: -220px;
            background-color: $primary;
            z-index: -1;
        }
    }
    .news-container{
        position: relative;
        padding-left: 25%;
        .link-rotate{
            position: absolute;
            left: 0;
            top: 260px;
        }
    }
}

// promo
.section-promo{
    overflow: hidden;
    margin: 125px 0;
    .section-title{
        padding-bottom: 20px;
    }

    .promo-container{
        padding-right: 25%;
        position: relative;
        &:before{
            content: "";
            position: absolute;
            top: -125px;
            bottom: -25px;
            left: 30%;
            width: 100vw;
            background-color: #dd232e;
            z-index: -1;
        }
    }
}

//solutions
.section-solutions{
    .section-title{
        padding-bottom: 20px;
    }
}

//b2b
.b2b{
    position: relative;
    padding: 200px 0;
    .b2b-content{
        max-width: 420px;
        position: relative;
        z-index: 2;
    }
    .b2b-image{
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
        text-align: right;
        max-width: 80%;
    }
}

//service
.home-service{
    position: relative;
    .service-background{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
    }
    .service-background-mask{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(22, 22, 29, 0.6);
    }
    .service-content{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 600px;
        padding: 120px 0;
        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p{
            color: $white;
        }
        .h1{
            text-transform: uppercase;
        }
    }
}


@include breakpoint(xlarge down) { 

    //news
    .section-news{
        .news-title{
            padding-bottom: 40px;
            .h1{
                font-size: 120px;
            }
            .h2{
                font-size: 56px;
            }
        }
    }

    // promo
    .section-promo{
        .section-title{
            padding-bottom: 25px;
        }
    }

    //b2b
    .b2b{
        padding: 120px 0px;
    }

    //service
    .home-service{
        .service-content{
            min-height: 460px;
        }
    }
}


@include breakpoint(large down) { 
    //news
    .section-news{
        .news-container{
            padding-left: 20%;
            .link-rotate{
                left: 20px;
            }
        }
    }
}


@include breakpoint(medium down) { 

    //news
    .section-news{
        .news-container{
            padding-left: 0;
            .link-rotate{
                display: none;
            }
            ul> li:nth-child(n+3){
                display: none;
            }
        }
    }

    //b2b
    .b2b{
        padding: 60px 0;
        .b2b-image{
            margin-top: 20px;
            position: static;
            max-width: 100%;
        }
    }

    //section propm
    .section-promo {
        .promo-container{
            padding-right: 0;
        }
    }
}


@include breakpoint(small down) {
    //news
    .section-news{
        .news-title{
            padding-bottom: 40px;
            .h1{
                font-size: 40px;
                color: rgba(255, 255, 255, 0.3);
            }
            .h2{
                font-size: 36px;
            }
        }
    }

    //promo
    .section-promo{
        margin: 60px 0;
    }

    //b2b
    .b2b{
        padding-bottom: 0;
    }

    //service
    .home-service{
        .service-content{
            min-height: 0;
            padding: 50px 0;
        }
    }
}


.cookie {
    max-height: 100vh;
    overflow-y: auto;
}


